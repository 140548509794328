import MainHeader from '../../components/mainHeader/Index.vue'
import MainFooter from '../../components/mainFooter/Index.vue'
import Dict from '../../components/dict/Index.vue'
import { saveProfile, getProfileById } from '@/api/talent'
import { getWeb3 } from '../../utils/web3'
export default {
  components: { MainHeader, MainFooter, Dict },
  data(){
    return{
      currentAccount: null,
      id: null,
      profileForm:{lookingFor:[]},
      rules: {
        firstName: [
          { required: true, message: 'Please input First Name', trigger: 'blur' },
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        lastName: [
          { required: true, message: 'Please input Last Name', trigger: 'blur' },
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        title: [
          { required: true, message: 'Please input Title', trigger: 'blur' },
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        linkedin: [
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        twitter: [
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        telegram: [
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        email: [
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'},
          {pattern: /^\w+([-\\.]\w+)*@\w+([\\.-]\w+)*\.\w{2,4}$/,message:'Invalid email', trigger: 'blur'}
        ],
        employer: [
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        status: [
          { required: true, message: 'Please select Job Type', trigger: 'change' }
        ],
        experienceLevel: [
          { required: true, message: 'Please select ExperienceLevel', trigger: 'change' }
        ],
        location: [
          { max: 50,message: "Maximum 50 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ],
        bio: [
          { required: true, message: 'Please input BIO', trigger: 'blur' },
          { max: 8000,message: "Maximum 8000 characters" },
          {pattern: /^[\w\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+$/,message:'Including invalid characters, English words only.', trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {
    this.id = this.$route.query.id || ''
    if(this.id!='')
    {
      this.queryProfile()
    }
  },
  watch:{
    currentAccount(newVal,oldVal){
      if(oldVal != null && newVal == null){
        this.toRouter('myProfile')
      }

      if(newVal != null && oldVal!=null && newVal!=oldVal){
        this.toRouter('myProfile')
      }
    }
  },
  methods: {
    checkEnglish(rule, value, callback){
      let reg = /[\u4e00-\u9fa5]/;
      let n = reg.test(value);
      if ( n ) {
        callback(new Error("Including invalid characters, English words only."))
      }
      else{
        callback();
      }
    },
    toRouter(name){
      this.$router.push({name:name})
    },
    async saveProfile(event){
      const target = event.currentTarget
      if(event.currentTarget.disabled)
        return
      event.currentTarget.disabled=true
      const loading = this.$loading({
        target: event.currentTarget,
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$refs['profileForm'].validate(async(valid) => {
        if (valid) {
          const that = this
          this.profileForm.cnxtToken = window.localStorage.getItem("LOGIN_ACCOUNT_ADDRESS");
          console.log(that.profileForm.cnxtToken)
          const web3  = await getWeb3()
          web3.eth.personal.sign(web3.utils.utf8ToHex("Save Profile Authorization."), that.profileForm.cnxtToken)
          .then(()=>{
                saveProfile(that.profileForm).then((resp)=>{
                  that.profileForm.id = resp.data;
                  that.$message({
                    message: 'Save profile success!',
                    type: 'success',
                    showClose: true
                  })
                  target.disabled=false
                  loading.close();
        
                  that.toRouter('myProfile')
                })
            
          }).catch(()=>{
            target.disabled=false
            loading.close();
          })

        } else {
          console.log('error submit!!');
          target.disabled=false
          loading.close();
          return false;
        }
      })
      
    },
    queryProfile(){
      const that = this
      if(this.id != null){
        getProfileById(this.id).then((resp) => {
          that.profileForm = resp.data
        });
      }
    }
  }
};